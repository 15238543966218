body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}
.LoginForm {
  height: 100vh;
}
.login_content {
  width: 70%;
}
.login_form {
  width: 100%;
}
.login_form .card {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login_form h6 {
  color: #134a80;
  margin-bottom: 20px;
  font-weight: 700;
  text-align: center;
}

.login_form form {
  display: flex;
  flex-direction: column;
}

.login_form label {
  text-align: left;
  margin-bottom: 5px;
}

.login_form input {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  outline: none;
}

.login_form .login_btn {
  padding: 8px;
  background-color: #134a80;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login_form .switch {
  margin-top: 15px;
  font-size: 14px;
}
.login_form .switch a {
  color: #28ad35;
  text-decoration: none;
  font-size: 16px;
}

.login_form .social_media {
  width: 80%;
}
.login_form .social_media .google {
  border-radius: 5px;
  padding: 6px;
  box-shadow: 0px 0px 4px 0px gray;
  background-color: #134a80;
  border: 1px solid #134a80;
  color: white;
  cursor: pointer;
}
.login_form .icon {
  font-size: 20px;
}
.login_form .google {
  width: 48%;
}
.login_form .card a {
  font-weight: 700;
}

.login_form .pass_visible {
  position: relative;
}
.login_form .password_toggle {
  position: absolute;
  top: 10px;
  right: 10px;
}

.login_form .avtar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px whitesmoke;
  padding: 10px;
}
.eyes {
  cursor: pointer;
}
