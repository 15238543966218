.myaccountleftbar {
  border: 1px solid #cecdcd;
  padding: 20px;
}

.myaccountleftbarbox2 {
  border: 1px solid #cecdcd;
  padding: 10px;
}

.myaccountleftbarbox5 {
  border: 1px solid #cecdcd;
  padding: 10px;
  cursor: pointer;
}

.account_setting {
  list-style-type: none;
}
.account_setting li {
  color: #7e7e7e;
  cursor: pointer;
}
.account_setting li:hover {
  text-decoration: underline;
}
.uploadImageButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #134a80;
  color: white;
  padding: 5px 25px;
  border-radius: 3px;
  border: none;
  outline: none;
  border: 1px solid #134a80;
}
.uploadImageButton:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: #134a80;
  padding: 5px 25px;
  border-radius: 5px;
}

.myaccountleftbar .Image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #134a80;
  padding: 10px;
}
.myaccountleftbar .Image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.mobile_profile {
  display: none;
}
.desktopProfile {
  display: block;
}

@media screen and (max-width: 767px) {
  .desktopProfile {
    display: none;
  }
  .mobile_profile {
    display: block;
  }
  .myaccountleftbar {
    border: none;
  }
  .mobile_profile .offcanvas {
    height: 100vh;
    overflow: scroll;
  }
}
