.WeatherRadar {
  padding: 3% 5%;
}

.WeatherRadar .WeatherRadar-card {
  box-shadow: 3px 3px 10px 3px rgb(240, 240, 240);
  border-radius: 12px;
}
.WeatherRadar .WeatherRadar-card img {
  width: 100%;
}

.weatherradar_image {
  height: 250px;
}

.WeatherRadar .WeatherRadar-card .WeatherRadar-details {
  padding: 4%;
}
.WeatherRadar .WeatherRadar-card .WeatherRadar-details-heart p {
  font-size: 20px;
  font-weight: 600;
  width: 90%;
}
.WeatherRadar .WeatherRadar-card .WeatherRadar-details-heart .heart_icon {
  /* color: #062c52; */
  font-size: 22px;
  cursor: pointer;
}
.WeatherRadar .WeatherRadar-card .WeatherRadar-details-heart .heart_icon_black {
  /* color: #062c52; */
  font-size: 22px;
  cursor: pointer;
}
.WeatherRadar .WeatherRadar-card .WeatherRadar-details .text-start {
  font-size: 16px;
  line-height: 22px;
}

.WeatherRadar .WeatherRadar_Arrowr_right {
  display: block;
  color: black;
  position: absolute;
  top: -50px;
  right: 2%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #134a80;
  padding: 8px;
  color: #134a80;
  cursor: pointer;
}
.WeatherRadar .WeatherRadar_Arrowr_left {
  color: black;
  position: absolute;
  top: -50px;
  left: 92%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #134a80;
  padding: 8px;
  color: #134a80;
  cursor: pointer;
}

.weatherradar_cardtext {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 1440px) {
  .weatherradar_image {
    height: 250px;
  }
}

@media screen and (max-width: 1440px) {
  .weatherradar_image {
    height: 200px;
  }
}

@media screen and (max-width: 1100px) {
  .WeatherRadar .WeatherRadar_Arrowr_left {
    left: 91%;
  }
}
@media screen and (max-width: 992px) {
  .WeatherRadar .WeatherRadar_Arrowr_left {
    left: 89%;
  }
}
@media screen and (max-width: 900px) {
  .WeatherRadar .WeatherRadar_Arrowr_left {
    left: 89%;
  }
  .weatherradar_image {
    height: 190px;
  }
}
@media screen and (max-width: 768px) {
  .WeatherRadar .WeatherRadar_Arrowr_left {
    display: none;
  }
  .WeatherRadar .WeatherRadar_Arrowr_right {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .weatherradar_image {
    height: 150px;
  }
}

@media screen and (max-width: 500px) {
  .weatherradar_image {
    height: 250px;
  }
}
