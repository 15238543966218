.SupplierPortfolio .header_img {
  height: 50vh;
  background: linear-gradient(#13498000, #13498084),
    url("../Public/images/potfolioHeaderImg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.SupplierPortfolio .PortfolioContent {
  width: 45%;
}
.SupplierPortfolio .potfolioTabs {
  background-color: #ecf1f5;
  padding: 7px;
  border-radius: 10px;
}
.SupplierPortfolio .potfolioTabs .nav-pills {
  width: 600px;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 17px;
  margin-bottom: -17px;
  overflow-y: hidden;
}

.SupplierPortfolio .potfolioTabs .nav-pills::-webkit-scrollbar {
  display: none;
}

.SupplierPortfolio .potfolioTabs .nav-pills {
  scrollbar-width: none;
}
.SupplierPortfolio .potfolioTabs .nav-pills .nav-link {
  color: black;
  padding: 8px 20px;
}
.SupplierPortfolio .potfolioTabs .nav-pills .nav-item {
  list-style-type: none;
}
.SupplierPortfolio .potfolioTabs .nav-pills .nav-link.active {
  background-color: #134a80;
  color: white;
}

.SupplierPortfolio .potfolioCard .images {
  height: 210px;
}
.SupplierPortfolio .potfolioCard .content h4 {
  font-size: 19px;
}
.SupplierPortfolio .potfolioCard {
  box-shadow: 3px 3px 20px 3px whitesmoke;
  padding: 10px;
  border-radius: 10px;
}
.SupplierPortfolio .potfolioCard .images img {
  width: 100%;
  height: 100%;
}
.SupplierPortfolio .tab-content {
  padding: 3% 5%;
}
.SupplierPortfolio .tab-content .content {
  padding: 10px 0px;
}
.SupplierPortfolio .tab-content .content p {
  text-align: justify;
  font-size: 15px;
}
.SupplierPortfolio .tab-content .content span {
  color: #134a80;
}
.SupplierPortfolio .tab-content button {
  padding: 6px 20px;
}

.SupplierPortfolio .tab-content .content .Download_Portfolio {
  background-color: white;
  color: black;
  border: 1px solid #134a80;
  border-radius: 5px;
  font-size: 14px;
}
.SupplierPortfolio .tab-content .content .Download_Portfolio:hover {
  background-color: #134a80;
  color: white;
  border: 1px solid #134a80;
  border-radius: 5px;
  font-size: 14px;
}

.SupplierPortfolio .tab-content .content .View_Details {
  background-color: #134a80;
  color: white;
  border: 1px solid #134a80;
  border-radius: 5px;
  font-size: 14px;
}
.SupplierPortfolio .tab-content .content .View_Details:hover {
  background-color: white;
  color: black;
  border: 1px solid #134a80;
  border-radius: 5px;
  font-size: 14px;
}

.SupplierPortfolio .tab-content > .active {
  display: flex;
}
@media screen and (max-width: 1550px) {
  .SupplierPortfolio .tab-content button {
    padding: 6px 10px;
  }
}
@media screen and (max-width: 1030px) {
  .SupplierPortfolio .tab-content button {
    padding: 8px 15px;
    font-size: 13px;
  }
  .SupplierPortfolio .potfolioCard .images {
    height: 220px;
  }
}
@media screen and (max-width: 950px) {
  .SupplierPortfolio .tab-content button {
    padding: 8px 12px;
    font-size: 14px;
  }
}
@media screen and (max-width: 850px) {
  .SupplierPortfolio .tab-content .content p {
    font-size: 15px;
  }
  .SupplierPortfolio .potfolioCard .images {
    height: 190px;
  }
  .SupplierPortfolio .tab-content button {
    padding: 8px 17px;
    font-size: 14px;
  }
}

@media screen and (max-width: 800px) {
  .SupplierPortfolio .PortfolioContent {
    width: 60%;
  }
}

@media screen and (max-width: 670px) {
  .SupplierPortfolio .tab-content button {
    padding: 8px 10px;
    font-size: 13px;
  }
}
@media screen and (max-width: 576px) {
  .SupplierPortfolio .PortfolioContent {
    width: 90%;
  }
  .SupplierPortfolio .tab-content button {
    padding: 8px 20px;
    font-size: 14px;
  }
}
@media screen and (max-width: 350px) {
  .SupplierPortfolio .tab-content button {
    padding: 8px 14px;
    font-size: 13px;
  }
}
