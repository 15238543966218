.RecentlyViewed {
  padding: 3% 5%;
  background-color: #eeeeee4f;
}

.RecentlyViewed-card {
  box-shadow: 3px 3px 10px 3px rgb(240, 240, 240);
  border-radius: 12px;
}

.recentlyView_image {
  height: 250px;
}

.RecentlyViewed-card .RecentlyViewed-details {
  padding: 4%;
}
.RecentlyViewed-card .RecentlyViewed-details-heart p {
  font-size: 20px;
  font-weight: 600;
  width: 90%;
}
.RecentlyViewed-card .RecentlyViewed-details-heart .heart_icon {
  color: #062c52;
  font-size: 22px;
  cursor: pointer;
}
.RecentlyViewed-card .RecentlyViewed-details .text-start {
  font-size: 16px;
  line-height: 22px;
}

.RecentlyViewed .RecentlyViewed_Arrowr_right {
  display: block;
  color: black;
  position: absolute;
  top: -50px;
  right: 2%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #134a80;
  padding: 8px;
  color: #134a80;
  cursor: pointer;
}

.RecentlyViewed .RecentlyViewed_Arrowr_left {
  color: black;
  position: absolute;
  top: -50px;
  left: 92%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #134a80;
  padding: 8px;
  color: #134a80;
  cursor: pointer;
}

.recentlyview_cardtext {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1440px) {
  .RecentlyViewed .recentlyView_image  {
    height: 200px;
  }
}

@media screen and (max-width: 1100px) {
  .RecentlyViewed .RecentlyViewed_Arrowr_left {
    left: 91%;
  }
}
@media screen and (max-width: 992px) {
  .RecentlyViewed .RecentlyViewed_Arrowr_left {
    left: 90%;
  }
}
@media screen and (max-width: 900px) {
  .RecentlyViewed .RecentlyViewed_Arrowr_left {
    left: 89%;
  }
}
@media screen and (max-width: 768px) {
  .RecentlyViewed .RecentlyViewed_Arrowr_left {
    display: none;
  }
  .RecentlyViewed .RecentlyViewed_Arrowr_right {
    display: none;
  }
}
@media screen and (max-width: 375px) {
  .RecentlyViewed #RecentlyViewed_viewAll {
    font-size: 13px;
    padding: 3px 10px;
  }
  .RecentlyViewed .RecentlyViewed-heading {
    font-size: 19px;
  }
}
