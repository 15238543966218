.bottom_compare_offcanvas {
  padding: 0% 5%;
}
.bottom_compare_offcanvas .bottomComparison_crossIcon {
  color: rgb(255, 0, 0);
  font-size: 15px;
}

.bottom_compare_offcanvas .bottomComparison_cross_icon_box {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 1px var(--color-border, rgb(0 0 0 / 10%)) inset;
  cursor: pointer;
}

.bottom_compare_offcanvas .card .card-body .compare_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
}

.offcanvas.offcanvas-bottom {
  height: auto !important;
}
.bottom_compare_offcanvas .shadow  {
  height: 160px;
}
