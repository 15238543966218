.DetailRecentView {
  padding: 3% 5%;
}

.DetailRecentView .DetailRecentView-card {
  box-shadow: 3px 3px 10px 3px rgb(240, 240, 240);
  border-radius: 12px;
}

.DetailRecentView .detailView_image {
  height: 250px;
}

.DetailRecentView .recentlyview_cardtext {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DetailRecentView .DetailRecentView-card .DetailRecentView-details {
  padding: 4%;
}
.DetailRecentView .DetailRecentView-card .DetailRecentViewt-details-heart p {
  font-size: 18px;
  font-weight: 600;
  width: 90%;
}
.DetailRecentView
  .DetailRecentView-card
  .DetailRecentViewt-details-heart
  .heart_icon {
  color: #062c52;
  font-size: 20px;
  cursor: pointer;
}
.DetailRecentView .DetailRecentView-card .FilterProduct-details .text-start {
  font-size: 13px;
  line-height: 22px;
}

@media screen and (max-width: 1440px) {
  .DetailRecentView .DetailRecentView-card .detailView_image {
    height: 200px;
  }
}
