.AboutUs .AboutHeader {
  height: 50vh;
  background: linear-gradient(#13498049, #13498084),
    url("../Public/images/AboutUsHeaderImage1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.SectionTwoboutUs {
  padding: 3% 5%;
}
.SectionTwoboutUs img {
  width: 90%;
  height: 400px;
}
.SectionTwoboutUs .about_description h6 {
  color: #134a80;
}
.SectionTwoboutUs .about_descriptio .text-dark {
  line-height: 40px;
}
.SectionTwoboutUs .about_description button {
  background-color: #134a80;
  border: none;
}
.SectionTwoboutUs .about_description p {
  line-height: 30px;
  text-align: justify;
}
.SectionTwoboutUs .about_description button:hover {
  color: black;
  background-color: white;
  border: 1px solid #134a80;
}

.aboutheader_content {
  width: 50%;
  text-align: left;
}

.AboutUs .Productcard {
  padding: 1% 5%;
}

.AboutUs .Productcard {
  img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
  }

  .hex {
    display: flex;
    position: relative;
    width: 240px;
    height: 265px;
    background-color: #424242;
    -webkit-clip-path: polygon(
      50% 0%,
      100% 25%,
      100% 75%,
      50% 100%,
      0% 75%,
      0% 25%
    );
    clip-path: polygon(50% 0%, 100% 25%, 100% 77%, 50% 100%, 0% 77%, 0% 25%);
  }
}

.hexicon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 25px;
  height: 30px;
  padding: 7px;
  background-color: #134a80;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 77%, 50% 100%, 0% 77%, 0% 25%);
}
.hexicon .media {
  color: white;
}

.AboutUs .Productcard a {
  background-color: #134a80;
  color: white;
}
.AboutUs .Productcard .row {
  box-shadow: 3px 3px 10px 3px rgb(240, 240, 240);
  padding: 20px;
}
.AboutUs .Productcard img {
  width: 100%;
}

.AboutUs .Productcard .card-block {
  text-align: start;
}
.AboutUs .Productcard .card-block h6 {
  color: #134a80;
}

.AboutUs .Productcard .card-text {
  text-align: justify;
}

/* Contact us -------------------------------------------- */
.contact-cards {
  padding: 3% 5%;
}

.contact-cards .card {
  background-color: #215070;
  display: table;
  width: 100%;
  height: 150px;
  color: #3498db;
  overflow: hidden;
  border-radius: 3px;
}
.contact-cards .card .icon {
  width: 48px;
  height: 100%;
  display: table-cell;
  position: relative;
  background-color: currentColor;
}
.contact-cards .card .icon:after {
  content: "";
  height: 100%;
  width: 0;
  position: absolute;
  right: -89px;
  top: 0;
  border-right: 90px solid transparent;
  border-left: 0px;
  border-bottom: 272px solid currentColor;
}
.contact-cards .card .icon .i {
  position: absolute;
  bottom: 25px;
  left: 25px;
  color: #fff;
  font-size: 35px;
  z-index: 1;
}
.contact-cards .card .content-wrap {
  padding: 10px;
  padding-left: 62px;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}
.contact-cards .card .content-wrap .item-title {
  display: inline-block;
  font-size: 16px;
  color: #a3baca;
  margin-bottom: 3px;
}
.contact-cards .card .content-wrap .text {
  color: #fff;
  font-size: 15px;
}

/* Our Missson section ------------------------ */

.OurMisson_secBorder p {
  width: 20%;
  height: 2px;
  background-color: #009cb5;
}
.OurMisson_sec {
  padding: 3% 5%;
}
.OurMisson_sec h3.white {
  margin: 0px 20px 20px 20px;
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  color: #444;
}
.OurMisson_sec .serviceBox {
  text-align: center;
  padding-top: 10px;
}

.OurMisson_sec .serviceBox .service-icon {
  color: #fff;
  background: #fff;
  font-size: 45px;
  line-height: 100px;
  width: 100px;
  height: 100px;
  margin: 0 auto 30px;
  border-radius: 50%;
  box-shadow: 5px 0 5px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
}

.OurMisson_sec .serviceBox .service-icon:before,
.serviceBox .service-icon:after {
  content: "";
  background: linear-gradient(to right, #199ef0 50%, transparent 50%);
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  z-index: -1;
  transition: all ease 0.8s;
}
.OurMisson_sec .serviceBox:hover .service-icon:before,
.serviceBox:hover .service-icon:after {
  transform: rotateZ(180deg);
}
.OurMisson_sec .serviceBox:hover .service-icon {
  box-shadow: -5px 1px 5px rgba(0, 0, 0, 0.2);
}

.OurMisson_sec .serviceBox .service-icon:after {
  background: #199ef0;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.3);
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
}
.OurMisson_sec .serviceBox {
  border: 1px solid #e5e4e4;
  padding: 40px 20px;
  border-radius: 100px 10px 100px 10px;

  background: #f9f9f9;
  position: relative;
  z-index: 1;
}

.OurMisson_sec .serviceBox .title {
  color: #199ef0;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 0 10px;
  font-weight: 700;
}

.OurMisson_sec .serviceBox .description {
  color: #777;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 0;
}

.OurMisson_sec .serviceBox.red .service-icon:before {
  background: linear-gradient(to right, #e34a3a 50%, transparent 50%);
}
.OurMisson_sec .serviceBox.red .service-icon:after {
  background: #e34a3a;
}
.OurMisson_sec .serviceBox.red .title {
  color: #e34a3a;
}

.OurMisson_sec .serviceBox.blue .service-icon:before {
  background: linear-gradient(to right, #009cb5 50%, transparent 50%);
}
.OurMisson_sec .serviceBox.blue .service-icon:after {
  background: #009cb5;
}
.OurMisson_sec .serviceBox.blue .title {
  color: #009cb5;
}

.OurMisson_sec .serviceBox.green .service-icon:before {
  background: linear-gradient(to right, #a1c52d 50%, transparent 50%);
}
.OurMisson_sec .serviceBox.green .service-icon:after {
  background: #a1c52d;
}
.OurMisson_sec .serviceBox.green .title {
  color: #a1c52d;
}
.ChooseUs_sec .main-timeline .timeline-icon:after {
  display: none;
}
.ChooseUs_sec .main-timeline .timeline-icon:before {
  display: none;
}

@media only screen and (max-width: 1440px) {
  .ChooseUs_sec .main-timeline .firstTimelineSec:after {
    top: 46%;
  }
  .ChooseUs_sec .main-timeline .timeline:nth-child(even) .timeline-icon {
    right: 33% !important;
  }
}

/* Why choose us section ----------------------------------------------------------------------------- */
.ChooseUs_sec {
  padding: 3% 5%;
}
.ChooseUs_sec a {
  text-decoration: none;
}
.ChooseUs_sec h3 {
  text-align: center;
  color: #444;
  text-align: left;
}
.ChooseUs_sec .main-timeline {
  position: relative;
}
.ChooseUs_sec .main-timeline:before {
  content: "";
  width: 5px;
  height: 100%;
  border-radius: 20px;
  margin: 0 auto;
  background: #242922;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.ChooseUs_sec .main-timeline .timeline {
  display: inline-block;
  margin-bottom: 50px;
  position: relative;
}
.ChooseUs_sec.main-timeline .timeline:before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #fff;
  background: #ec496e;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}
.ChooseUs_sec .main-timeline .timeline-icon {
  display: inline-block;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 3px solid #ec496e;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translateY(-50%);
}
.ChooseUs_sec .main-timeline .timeline-icon .iconOne {
  width: 100px;
  height: 100px;
  font-size: 40px;
  border-radius: 50%;
  color: #ec496e;
}

.ChooseUs_sec .main-timeline .timeline-icon .icontwo {
  width: 100px;
  height: 100px;
  font-size: 40px;
  border-radius: 50%;
  color: #ec496e;
}

.ChooseUs_sec .main-timeline .timeline-icon .iconthree {
  width: 100px;
  height: 100px;
  font-size: 40px;
  border-radius: 50%;
  color: #8fb800;
}

.ChooseUs_sec .main-timeline .timeline-icon .iconfour {
  width: 100px;
  height: 100px;
  font-size: 40px;
  border-radius: 50%;
  color: #2fcea5;
}

.ChooseUs_sec .main-timeline .timeline-icon:after,
.ChooseUs_sec .main-timeline .timeline-icon:before {
  content: "";
  width: 100px;
  height: 4px;
  background: transparent;
  position: absolute;
  top: 46%;
  right: -100px;
  transform: translateY(-50%);
}
.ChooseUs_sec .main-timeline .firstTimelineSec:after {
  width: 100px;
  height: 4px;
  background: #ec496e;
  position: absolute;
  top: 46%;
  right: -100px;
  transform: translateY(-50%);
}

.ChooseUs_sec .main-timeline .timeline-content {
  width: 50%;
  padding: 0 50px;
  margin: 52px 0 0;
  float: right;
  position: relative;
  text-align: left;
}
.ChooseUs_sec .main-timeline .timeline-content:before {
  content: "";
  width: 70%;
  height: 100%;
  border: 3px solid #ec496e;
  border-top: none;
  border-right: none;
  position: absolute;
  bottom: -13px;
  left: 35px;
}
.ChooseUs_sec .main-timeline .timeline-content:after {
  content: "";
  width: 37px;
  height: 3px;
  background: #ec496e;
  position: absolute;
  top: 13px;
  left: 0;
}
.ChooseUs_sec .main-timeline .title {
  font-size: 20px;
  font-weight: 600;
  color: #ec496e;
  text-transform: uppercase;
  margin: 0 0 5px;
}
.ChooseUs_sec .main-timeline .description {
  display: inline-block;
  font-size: 16px;
  color: #404040;
  line-height: 20px;
  letter-spacing: 1px;
  margin: 0;
}
.ChooseUs_sec .main-timeline .timeline:nth-child(even) .timeline-icon {
  left: auto;
  right: 30%;
}
.ChooseUs_sec .main-timeline .timeline:nth-child(even) .timeline-icon:before {
  right: auto;
  left: -100px;
}
.ChooseUs_sec .main-timeline .timeline:nth-child(even) .timeline-icon:after {
  right: auto;
  left: -30px;
}
.ChooseUs_sec .main-timeline .timeline:nth-child(even) .timeline-content {
  float: left;
}
.ChooseUs_sec
  .main-timeline
  .timeline:nth-child(even)
  .timeline-content:before {
  left: auto;
  right: 35px;
  transform: rotateY(180deg);
}
.ChooseUs_sec .main-timeline .timeline:nth-child(even) .timeline-content:after {
  left: auto;
  right: 0;
}
.main-timeline .timeline:nth-child(2n) .timeline-content:after,
.main-timeline .timeline:nth-child(2n) .timeline-icon i,
.main-timeline .timeline:nth-child(2n) .timeline-icon:before,
.main-timeline .timeline:nth-child(2n):before {
  background: #f9850f;
}
.ChooseUs_sec .main-timeline .timeline:nth-child(2n) .timeline-icon {
  border-color: #f9850f;
}
.ChooseUs_sec .main-timeline .timeline:nth-child(2n) .title {
  color: #f9850f;
}
.ChooseUs_sec .main-timeline .timeline:nth-child(2n) .timeline-content:before {
  border-left-color: #f9850f;
  border-bottom-color: #f9850f;
}
.main-timeline .timeline:nth-child(3n) .timeline-content:after,
.main-timeline .timeline:nth-child(3n) .timeline-icon i,
.main-timeline .timeline:nth-child(3n) .timeline-icon:before,
.ChooseUs_sec .main-timeline .timeline:nth-child(3n):before {
  background: #8fb800;
}
.ChooseUs_sec .main-timeline .timeline:nth-child(3n) .timeline-icon {
  border-color: #8fb800;
}
.ChooseUs_sec .main-timeline .timeline:nth-child(3n) .title {
  color: #8fb800;
}
.ChooseUs_sec .main-timeline .timeline:nth-child(3n) .timeline-content:before {
  border-left-color: #8fb800;
  border-bottom-color: #8fb800;
}
.main-timeline .timeline:nth-child(4n) .timeline-content:after,
.main-timeline .timeline:nth-child(4n) .timeline-icon i,
.main-timeline .timeline:nth-child(4n) .timeline-icon:before,
.ChooseUs_sec .main-timeline .timeline:nth-child(4n):before {
  background: #2fcea5;
}
.ChooseUs_sec .main-timeline .timeline:nth-child(4n) .timeline-icon {
  border-color: #2fcea5;
}
.ChooseUs_sec .main-timeline .timeline:nth-child(4n) .title {
  color: #2fcea5;
}
.ChooseUs_sec .main-timeline .timeline:nth-child(4n) .timeline-content:before {
  border-left-color: #2fcea5;
  border-bottom-color: #2fcea5;
}
@media only screen and (max-width: 1200px) {
  .ChooseUs_sec .main-timeline .timeline-content:after {
    top: 0px;
  }
  .ChooseUs_sec .main-timeline .timeline-content:before {
    bottom: 0px;
  }
  .ChooseUs_sec .main-timeline .timeline-icon:before {
    right: -53px;
  }
  .ChooseUs_sec .main-timeline .timeline-icon:before {
    width: 50px;
    right: -50px;
  }
  .ChooseUs_sec .main-timeline .timeline:nth-child(even) .timeline-icon:before {
    right: auto;
    left: -50px;
  }
  .ChooseUs_sec .main-timeline .timeline-content {
    margin-top: 75px;
  }
}

@media only screen and (max-width: 1010px) {
  .ChooseUs_sec .main-timeline .firstTimelineSec:after {
    width: 50px;
    right: -52px;
  }
  .AboutUs .Productcard .hex {
    width: 220px;
    height: 245px;
  }
}

@media only screen and (max-width: 990px) {
  .SectionTwoboutUs img {
    height: 400px;
    width: 100%;
  }
  .OurMisson_sec .serviceBox {
    margin: 0 0 30px;
  }
  .ChooseUs_sec .main-timeline .timeline {
    margin: 0 0 10px;
  }
  .aboutheader_content {
    width: 70%;
  }
  .ChooseUs_sec .main-timeline .timeline-icon {
    left: 25%;
  }

  .ChooseUs_sec .main-timeline .timeline-content {
    margin-top: 115px;
  }
  .ChooseUs_sec .main-timeline .timeline:nth-child(even) .timeline-icon {
    right: 25% !important;
  }
}
/* @media only screen and (max-width: 950px) {
  .contact-cards .card {
    height: 240px;
  }
} */
@media only screen and (max-width: 900px) {
  .AboutUs .Productcard .hex {
    width: 190px;
    height: 215px;
  }
}
@media only screen and (max-width: 768px) {
  .Productcard .card-block h4 {
    font-size: 20px;
  }
  .SectionTwoboutUs img {
    height: 300px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .ChooseUs_sec .main-timeline .timeline-icon:before {
    display: none;
  }
  .ChooseUs_sec .main-timeline .timeline-content {
    padding: 10px 10px;
  }
  .ChooseUs_sec .main-timeline .timeline-content:after {
    display: none;
  }
  .ChooseUs_sec
    .main-timeline
    .timeline:nth-child(2n)
    .timeline-content:before {
    display: none;
  }
  .ChooseUs_sec .main-timeline .timeline-content:before {
    display: none;
  }
  .ChooseUs_sec .main-timeline .timeline:nth-child(even) .timeline-icon:before {
    display: none;
  }
  .ChooseUs_sec .main-timeline .timeline-icon:after {
    display: none;
  }
  .ChooseUs_sec
    .main-timeline
    .timeline:nth-child(even)
    .timeline-content:after {
    display: none;
  }
  .ChooseUs_sec .main-timeline:before {
    display: none;
  }
  .ChooseUs_sec .main-timeline {
    padding-top: 50px;
  }
  .ChooseUs_sec.main-timeline:before {
    left: 80px;
    right: 0;
    margin: 0;
  }
  .ChooseUs_sec .main-timeline .timeline {
    margin-bottom: 70px;
  }
  .ChooseUs_sec .main-timeline .timeline:before {
    top: 0;
    left: 83px;
    right: 0;
    margin: 0;
  }
  .ChooseUs_sec .main-timeline .timeline-icon {
    width: 60px;
    height: 60px;
    line-height: 40px;
    padding: 5px;
    top: 0;
    left: 0;
  }
  .ChooseUs_sec .main-timeline .timeline:nth-child(even) .timeline-icon {
    left: 0;
    right: auto;
  }
  .ChooseUs_sec .main-timeline .timeline-icon:before,
  .main-timeline .timeline:nth-child(even) .timeline-icon:before {
    width: 25px;
    left: auto;
    right: -25px;
  }
  .ChooseUs_sec .main-timeline .timeline-icon:after,
  .main-timeline .timeline:nth-child(even) .timeline-icon:after {
    width: 25px;
    height: 30px;
    top: 44px;
    left: auto;
    right: -5px;
  }
  .ChooseUs_sec .main-timeline .timeline-icon i {
    font-size: 30px;
    line-height: 45px;
  }
  .ChooseUs_sec .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(even) .timeline-content {
    width: 100%;
    margin-top: -15px;
    padding-left: 130px;
    padding-right: 5px;
  }
  .ChooseUs_sec .main-timeline .timeline:nth-child(even) .timeline-content {
    float: right;
  }
  .ChooseUs_sec .main-timeline .timeline-content:before,
  .main-timeline .timeline:nth-child(even) .timeline-content:before {
    width: 50%;
    left: 120px;
  }
  .ChooseUs_sec
    .main-timeline
    .timeline:nth-child(even)
    .timeline-content:before {
    right: auto;
    transform: rotateY(0);
  }
  .ChooseUs_sec .main-timeline .timeline-content:after,
  .main-timeline .timeline:nth-child(even) .timeline-content:after {
    left: 85px;
  }
}
@media only screen and (max-width: 479px) {
  .SectionTwoboutUs img {
    height: 250px;
    width: 100%;
  }
  .ChooseUs_sec .main-timeline .timeline-content {
    padding-left: 80px !important;
  }
  .aboutheader_content {
    width: 90%;
  }
  .ChooseUs_sec .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(2n) .timeline-content {
    padding-left: 110px;
  }
  .ChooseUs_sec .main-timeline .timeline-content:before,
  .main-timeline .timeline:nth-child(2n) .timeline-content:before {
    left: 99px;
  }
  .ChooseUs_sec .main-timeline .timeline-content:after,
  .main-timeline .timeline:nth-child(2n) .timeline-content:after {
    left: 65px;
  }
}

@media only screen and (max-width: 320px) {
  .OurMisson_sec h3.white {
    margin: 0px 0px 20px 0px;
  }
}
