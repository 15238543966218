.modalpopup_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modalpopup_image {
  height: 220px;
  width: 100%;
}

.modalpopup_button {
  background-color: #134a80;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  border: none;
}

.modalpopup_title {
  background: #134a80;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.modalpopup-details {
  padding: 4%;
  width: 100%;
}

.modalpopup-details-heart p {
  font-size: 16px;
  font-weight: 600;
}
.modalpopup-card img {
  width: 100%;
}

.modalpopup-card {
  box-shadow: 3px 3px 10px 3px rgb(240, 240, 240);
  border-radius: 10px;
  padding: 10px;
  height: 100%;
}
.modalpopup-card .plus_icon {
  font-size: 30px;
  color: #062c52;
  cursor: pointer;
}
.modalpopup-card {
  position: relative;
}
