.EditProduct {
  padding: 2% 5%;
  background-color: #eee;
}
.EditProduct h4 {
  color: #134a80;
}
.EditProduct .EditProduct-card .EditProduct_image img {
  width: 100%;
}

.EditProduct .EditProduct-card-content {
  background-color: white;
  height: 100%;
  padding: 0% 4%;
  position: relative;
  border-radius: 0px 8px 8px 0px;
}
.EditProduct .EditProduct-card-content p {
  font-size: 13px;
}

.EditProduct-cross {
  position: absolute;
  top: 7px;
  right: 10px;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
}
.EditProduct-compare {
  position: relative;
}

.EditProduct-compare h4 {
  background-color: #134a80;
  border-radius: 10px;
  padding: 8px;
  color: white;
  font-size: 20px;
  font-weight: 400;
}
.EditProduct-compare-cross {
  position: absolute;
  font-size: 22px;
  font-weight: bold;
  top: -40px;
  right: 0px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .EditProduct-compare-cross {
    top: -23px;
  }
}
