.news_sidebar .news_Category .category-title {
  padding: 10px 10px 20px 10px;
  width: 100%;
  text-align: start;
}
.news_sidebar .news_Category {
  border: 2px solid #eeeeee;
  border-radius: 10px;
  padding: 10px 0px 15px 0px;
}
.news_sidebar .news_Category .category-title h5 {
  color: #134a80;
}
.news_sidebar .news_Category .category-title .progresbar {
  width: 100%;
  background-color: #ebe5e5;
  border-radius: 5px;
}

.progres_line {
  background-color: #134a80;
  padding: 2px;
  width: 35%;
}
.NewsFilters {
  text-align: start;
}
.NewsFilters p {
  padding: 0px 10px;
  font-weight: bold;
}
.NewsFilters hr {
  margin: 10px 0px;
  border: 1px solid;
}

.NewsFilters .details .iconsSec span {
  font-size: 10px;
}
.NewsFilters .details .iconsSec .i {
  font-size: 12px;
  color: #134a80;
}
.NewsFilters .recent_viewSec {
  padding: 0px 10px;
  overflow: hidden;
  width: 100%;
}
.NewsFilters .recent_viewSec .leftSec img {
  width: 100%;
  border-radius: 7px;
}
.NewsFilters .recent_viewSec .leftSec {
  width: 33%;
}
.NewsFilters .recent_viewSec .right_Sec {
  width: 62%;
}
.desktop_news_sidebar {
  display: block;
}
.newwMobileBar {
  display: none;
}

/* mobile...................................... */

@media screen and (max-width: 1130px) {
  .news_Category .NewsFilters .right_Sec h6 {
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) {
  .desktop_news_sidebar {
    display: none;
  }
  .newwMobileBar {
    display: block;
  }

  .newwMobileBar .offcanvas {
    height: 100vh;
    overflow: scroll;
  }
}
