footer {
  background-color: #134a80;
  padding: 1% 5%;
}
footer p {
  color: white;
  cursor: pointer;
}
footer img {
  cursor: pointer;
}

footer .otherPages {
  padding: 0% 5%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
footer .otherPages p {
  border-style: none solid none none;
  border-width: 1px;
  padding-right: 30px;
}
#video_sec {
  border: none;
}

footer .footer_hrLine {
  color: white;
  border: 1px solid white;
}
footer .social_media {
  color: white;
  font-size: 22px;
}
@media screen and (max-width: 1200px) {
  footer .otherPages p {
    font-size: 13px;
  }
}
@media screen and (max-width: 1200px) {
  footer .otherPages p {
    padding-right: 20px;
  }
}
@media screen and (max-width: 950px) {
  footer .otherPages p {
    padding-right: 13px;
  }
}
@media screen and (max-width: 900px) {
  footer .otherPages p {
    padding-right: 10px;
  }
}

@media screen and (max-width: 775px) {
  /* footer .otherPages p {
    border: none;
  } */
  footer .otherPages {
    padding: 0% 5%;
    display: flex;
    justify-content: start !important;
    flex-wrap: wrap;
  }
  footer .otherPages p {
    padding-left: 8px;
  }
}
