.Detail_page_Table {
    padding:3% 5%;
}

.Detail_page_Table table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .Detail_page_Table td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
 .Detail_page_Table tr:nth-child(even) {
    background-color: #dddddd;
  }
  .Detail_page_Table_heading{
    background-color: #134A80;
    color:white
  }