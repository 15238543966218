.NewPassModal {
  height: 100vh;
}
.Lable {
  text-align: left;
}
.NewPassModal .confirm_box .input_type {
  position: relative;
  display: flex;
  flex-direction: column;
}
.NewPassModal .confirm_box [type="password"] {
  font-size: 14px;
  outline: none !important;
}

.NewPassModal .confirm_box .conform_button {
  width: 100%;
  background-color: #134a80;
  color: white;
  border: none;
}
.NewPassModal .confirm_box .eyes {
  position: absolute;
  top: 5px;
  right: 10px;
}

.NewPassModal .confirm_box img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px whitesmoke;
  padding: 10px;
}
.NewPassModal .confirm_box h4 {
  color: #134a80;
  margin-top: 6px;
}

.error {
  text-align: left;
}
