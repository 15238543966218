.FilterSideBar .card {
  background-color: #eeeeee;
}

.FilterSideBar .card .card-group-item .card-header {
  padding: 8% 0%;
}

.FilterSideBar .FilterSideBar_slider {
  width: 100%;
  height: 6px;
  background-color: #134a80;
  border-radius: 5px;
}
.FilterSideBar .FilterSideBar_slider .thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  top: -5px;
}
.FilterSideBar hr {
  margin: 0px;
}
.FilterSideBar input[type="checkbox"] {
  box-shadow: none !important;
}

.FilterSideBar input[type="checkbox"]:checked {
  background-color: #134a80 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}
.Mobile_filter_bar {
  display: none;
}

@media screen and (max-width: 1100px) {
  .FilterSideBar
    .card
    .card-group-item
    .filter-content
    .form-check
    .form-check-label {
    font-size: 14px;
  }
}
@media screen and (max-width: 900px) {
  .FilterSideBar .card .card-group-item .filter-content .card-body {
    padding: 15px 5px;
  }
  .FilterSideBar .card .card-group-item .filter-content .card-body p {
    font-size: 13px;
  }
  .FilterSideBar
    .card
    .card-group-item
    .filter-content
    .form-check
    .form-check-label {
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) {
  .filterDesktop {
    display: none;
  }
  .Mobile_filter_bar {
    display: block;
  }
  .Mobile_filter_bar .offcanvas {
    height: 100vh;
    overflow: scroll;
  }
  .Mobile_filter_bar {
    position: relative;
  }
  .filter_offcanvasToggle {
    position: absolute;
    top: -40px;
    left: -25px;
  }
  .Mobile_filter_bar .card-header .title {
    padding: 10px 20px;
  }
  .Mobile_filter_bar
    .FilterSideBar
    .card
    .card-group-item
    .filter-content
    .card-body {
    padding: 15px 20px;
  }
  .Mobile_filter_bar .FilterSideBar .card {
    border-radius: 0px;
  }
  .Mobile_filter_bar .offcanvas.show:not(.hiding),
  .offcanvas.showing {
    width: 280px !important;
  }
  .Mobile_filter_bar .offcanvas.offcanvas-start {
    width: 280px !important;
  }
}
