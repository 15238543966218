.NewsDetails {
  padding: 3% 5%;
}
.NewsDetails .recentPost .news_Category .category-title {
  padding: 10px 10px 20px 10px;
  width: 100%;
  text-align: start;
}
.NewsDetails .recentPost .news_Category {
  border: 2px solid #eeeeee;
  border-radius: 10px;
  padding: 10px 0px 15px 0px;
}
.NewsDetails .recentPost .news_Category .category-title h5 {
  color: #134a80;
}
.NewsDetails .recentPost .news_Category .category-title .progresbar {
  width: 100%;
  background-color: #ebe5e5;
  border-radius: 5px;
}

.NewsDetails .progres_line {
  background-color: #134a80;
  padding: 2px;
  width: 35%;
}
.NewsDetails .NewsFilters {
  text-align: start;
}
.NewsDetails .NewsFilters p {
  padding: 0px 10px;
  font-weight: bold;
}
.NewsDetails .NewsFilters hr {
  margin: 10px 0px;
  border: 1px solid;
}

.NewsDetails .NewsFilters .details .iconsSec span {
  font-size: 10px;
}
.NewsDetails .NewsFilters .details .iconsSec .i {
  font-size: 12px;
  color: #134a80;
}
.NewsDetails .NewsFilters .recent_viewSec {
  padding: 0px 10px;
  overflow: hidden;
  width: 100%;
}
.NewsDetails .NewsFilters .recent_viewSec .leftSec img {
  width: 100%;
  border-radius: 7px;
}
.NewsDetails .NewsFilters .recent_viewSec .leftSec {
  width: 33%;
}
.NewsDetails .NewsFilters .recent_viewSec .right_Sec {
  width: 62%;
}

.NewsDetails .newsDetailsleftSec .imagesec {
  height: 500px;
}
.NewsDetails .newsDetailsleftSec .imagesec .newsproductImg {
  width: 100%;
  height: 400px;
}
.NewsDetails .newsDetailsleftSec .newsDetailsleftSec_content {
  text-align: justify;
}

@media screen and (max-width: 767px) {
  .NewsDetails .news_Category .NewsFilters .right_Sec h6 {
    font-size: 20px !important;
  }
  .NewsDetails .NewsFilters .details .iconsSec span {
    font-size: 14px !important;
  }
  .NewsDetails .NewsFilters .details .iconsSec .i {
    font-size: 16px !important;
  }
}
