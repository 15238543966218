.main_otp_box {
  height: 100vh;
}
.main_otp_box .Otp {
  padding: 10px;
  border-radius: 10px;
  color: #134a80;
}
.main_otp_box input[type="text"] {
  width: 40px !important;
  height: 40px;
  margin-right: 20px;
  text-align: center;
}
.main_otp_box .otp_box {
  width: 100px;
}
.main_otp_box .Otp .Verify-OTP {
  background-color: #134a80;
  border: none;
}

.main_otp_box .login_form .avtar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px whitesmoke;
  padding: 10px;
}
