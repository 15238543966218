.ForecastingPlatform {
  padding: 3% 5%;
}

.ForecastingPlatform-card {
  box-shadow: 3px 3px 10px 3px rgb(240, 240, 240);
  border-radius: 12px;
}

.forecastingplatform_image {
  height: 250px;
}

.ForecastingPlatform-card .ForecastingPlatform-details {
  padding: 4%;
}
.ForecastingPlatform-card .ForecastingPlatform-details-heart p {
  font-size: 20px;
  font-weight: 600;
  width: 90%;
}
.ForecastingPlatform-card .ForecastingPlatform-details-heart .heart_icon {
  color: #062c52;
  font-size: 22px;
  cursor:pointer;
}
.ForecastingPlatform-card .ForecastingPlatform-details .text-start {
  font-size: 16px;
  line-height: 22px;
}

.WeatherRadar-heading {
  font-weight: 600;
}

.forecastingplatform_cardtext{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ForecastingPlatform .ForecastingPlatform_Arrowr_right {
  display: block;
  color: black;
  position: absolute;
  top: -50px;
  right: 2%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #134a80;
  padding: 8px;
  color: #134a80;
  cursor: pointer;
}

.ForecastingPlatform .ForecastingPlatform_Arrowr_left {
  color: black;
  position: absolute;
  top: -50px;
  left: 92%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #134a80;
  padding: 8px;
  color: #134a80;
  cursor: pointer;
}

@media screen and (max-width: 1440px){
  .ForecastingPlatform  .forecastingplatform_image{
    height: 250px;
}
}

@media screen and (max-width: 1440px){
  .ForecastingPlatform  .forecastingplatform_image{
    height: 200px;
}
}

@media screen and (max-width: 1100px) {
  .ForecastingPlatform .ForecastingPlatform_Arrowr_left {
    left: 91%;
  }
}
@media screen and (max-width: 992px) {
  .ForecastingPlatform .ForecastingPlatform_Arrowr_left {
    left: 90%;
  }
}
@media screen and (max-width: 900px) {
  .ForecastingPlatform .ForecastingPlatform_Arrowr_left {
    left: 89%;
  }
}
@media screen and (max-width: 768px) {
  .ForecastingPlatform .ForecastingPlatform_Arrowr_left {
    display: none;
  }
  .ForecastingPlatform .ForecastingPlatform_Arrowr_right {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .ForecastingPlatform  .forecastingplatform_image{
    height: 150px;
}
}

@media screen and (max-width: 500px) {
  .ForecastingPlatform  .forecastingplatform_image{
    height: 250px;
}
}

