.ImageSlider {
  padding: 5% 5%;
}
.ImageSlider .mainBox_slider .customSlider .image_sec {
  height: 300px;
}
.ImageSlider .mainBox_slider .customSlider {
  position: relative;
  overflow-x: hidden;
}
.ImageSlider .mainBox_slider .customSlider .image_sec img {
  width: 100%;
  height: 100%;
  background: #eeeeee;
}
.ImageSlider_heart {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 25px;
  color: #134a80;
  cursor: pointer;
}
.ImageSlider-button {
  background-color: #134a80;
  color: white;
}
.ImageSlider .title {
  text-align: justify;
}
.ImageSlider .ImageSlider-small_images{
  cursor: pointer;
  background: #eeeeee;
}

@media screen and (max-width: 500px) {
  .customSlider  .flex-wrap{
    padding:10px;
  }

}
