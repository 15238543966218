.nav_bar .deskTopNavbar {
  padding: 0.2% 5%;
}
.nav_bar {
  background-color: #134a80;
  padding: 0% 0%;
  /* position: -webkit-sticky;
  position: sticky;
  width: 100%;
  z-index: 99999;
  top: 0; */
}

.nav_bar .deskTopNavbar .navbar-logoSec img {
  width: 100%;
  cursor: pointer;
}
.nav_bar .deskTopNavbar .navbar-categores_Sec {
  color: white;
}

.nav_bar .deskTopNavbar .navbar-categores_Sec ul {
  margin: 0px;
  /* box-shadow: 0px 0px 5px 0px whitesmoke; */
}
.nav_bar .deskTopNavbar .navbar-categores_Sec ul li {
  padding: 10px;
  border: 0px 0px 1px 0px;
  border-color: white;
}
.nav_bar .deskTopNavbar .navbar-categores_Sec ul li:hover {
  background-color: #134a80;
  color: white;
  border-radius: 4px;
}
.nav_bar .deskTopNavbar .navbar-categores_Sec p {
  font-size: 16px;
}
.nav_bar .deskTopNavbar .navbar-searchSec input {
  width: 100%;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 8px 15px;
}
.nav_bar .deskTopNavbar .navbar-searchSec {
  position: relative;
}

.nav_bar .deskTopNavbar .navbar-searchSec ::placeholder {
  font-size: 13px;
}
.nav_bar .deskTopNavbar .navbar-search_icon {
  position: absolute;
  right: 10px;
  top: 26%;
  color: rgb(124 124 124);
  font-weight: 600;
  font-size: 22px;
  cursor: pointer;
}
.nav_bar .Categories_bar_icon {
  cursor: pointer;
}
.nav_bar .deskTopNavbar .navbar-right_section .icons {
  color: white;
  font-size: 23px;
  cursor: pointer;
}
.nav_bar .deskTopNavbar .navbar-right_section p {
  color: white;
  font-size: 12px;
  margin: 0px;
}

.navbar-hrLine {
  width: 100%;
  border: 1px solid white;
}
.nav_bar-bottom_sec {
  padding: 0.5% 5%;
}

.nav_bar-bottom_sec-center p {
  color: #55f66a;
  font-size: 14px;
  cursor: pointer;
}
.nav_bar .shadow-lg {
  width: 200px;
  text-align: left;
  border-radius: 5px;
  cursor: pointer;
}
.nav_bar .shadow-lg ul li:hover {
  color: #55f66a;
}
.offcanvas-body p {
  cursor: pointer;
}

.dropdown {
  background-color: white;
  width: 100%;
  color: black;
  display: block;
  position: absolute;
  z-index: 999;
  top: 34px;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown ul {
  margin: 0px;
  padding: 5px 0px;
  line-height: 35px;
  cursor: pointer;
}
.dropdown ul li:hover {
  background-color: #134a80;
  color: white;
  cursor: pointer;
}
.wishlistIcon {
  position: relative;
}
.numbers {
  position: absolute;
  top: -5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  color: white;
  right: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-comparer_Sec:hover .compare {
  display: block !important;
}

/* compare dropdown ============================== */
.nav_bar .deskTopNavbar .navbar-comparer_Sec {
  color: white;
}

.nav_bar .deskTopNavbar .navbar-comparer_Sec ul {
  margin: 0px;
  /* box-shadow: 0px 0px 5px 0px whitesmoke; */
}
.nav_bar .deskTopNavbar .navbar-comparer_Sec ul li {
  padding: 10px;
  border: 0px 0px 1px 0px;
  border-color: white;
}
.nav_bar .deskTopNavbar .navbar-comparer_Sec ul li:hover {
  background-color: #134a80;
  color: white;
}

.compare {
  width: 200px;
  text-align: left;
  border-radius: 5px;
  cursor: pointer;
}

/* Mobile NavBar Responsive ------------------------------------------- */
.MobileNavBar {
  display: none;
}
@media screen and (max-width: 1050px) {
  .nav_bar .deskTopNavbar .navbar-categores_Sec p {
    font-size: 12px;
  }

  .nav_bar-bottom_sec-center p {
    font-size: 12px;
  }
}
@media screen and (max-width: 992px) {
  .nav_bar .deskTopNavbar .navbar-right_section p {
    font-size: 10px;
  }
}

@media screen and (max-width: 769px) {
  .nav_bar .deskTopNavbar .navbar-categores_Sec p {
    font-size: 16px;
  }
  .MobileNavBar {
    padding: 1% 2%;
  }
  .nav_bar .deskTopNavbar .navbar-right_section p {
    font-size: 9px;
  }
  .MobileNavBar .navbar-logoSec img {
    width: 80%;
  }
  .nav_bar .deskTopNavbar .navbar-logoSec img {
    width: 90%;
    padding: 0px;
  }
  .MobileNavBar .offcanvas {
    background-color: #eeeeee;
  }
  .nav_bar-bottom_sec {
    padding: 0.5% 4%;
  }
  .nav_bar .deskTopNavbar .navbar-categores_Sec p {
    font-size: 16px;
  }
  .nav_bar-bottom_sec {
    padding: 1% 4%;
  }
}
@media screen and (max-width: 767px) {
  .nav_bar .deskTopNavbar .navbar-right_section p {
    font-size: 12px !important;
  }
  .nav_bar .deskTopNavbar .navbar-logoSec img {
    width: 30%;
    padding: 0px;
  }
  .MobileNavBar {
    display: block;
    padding: 1% 5%;
    position: absolute;
    top: 25px;
    right: 0px;
  }
}
@media screen and (max-width: 430px) {
  .nav_bar .deskTopNavbar .navbar-logoSec img {
    width: 40%;
    padding: 0px;
  }
  #categoriesDropdown {
    position: absolute;
    top: 40px;
    background: white;
    color: black;
    left: -9px;
    z-index: 99;
  }
}

@media screen and (max-width: 375px) {
  .nav_bar-bottom_sec {
    padding: 0.5% 4%;
  }
  .nav_bar-bottom_sec-center p {
    font-size: 12px;
  }
  .nav_bar-bottom_sec {
    padding: 1.5% 4%;
  }
}
