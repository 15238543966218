.FilterProductVertical_card {
  box-shadow: 3px 3px 10px 3px rgb(240, 240, 240);
  border-radius: 12px;
}

.FilterProductVertical_card img {
  border-radius: 0px !important;
}
.FilterProductVertical_card_details {
  padding: 20px;
}

.FilterProductVertical_heart_icon {
  font-size: 30px;
  cursor: pointer;
}
.verticalfilterImage {
  height: 250px;
}

@media screen and (max-width: 768px) {
  .FilterProductVertical_heart_icon {
    font-size: 20px;
  }
}
