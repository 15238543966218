.terms_content {
  padding: 3% 5%;
  text-align: justify;
}

.Terms .Terms_header {
  height: 50vh;
  background: linear-gradient(#13498049, #13498084),
    url("../../src/Public/images/termsHeader_img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.Terms .Terms_header_content {
  width: 60%;
}

@media only screen and (max-width: 900px) {
  .Terms .Terms_header_content {
    width: 80%;
  }
  .Terms .Terms_header_content p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 500px) {
  .Terms .Terms_header_content {
    width: 95%;
  }
  .Terms .Terms_header_content p {
    font-size: 12px;
  }
}
