.ForgotPassword {
  height: 100vh;
}

.login_content h4 {
  text-align: center;
}
.Forgot_form {
  width: 100%;
}
.Forgot_form .card {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.Forgot_form h4 {
  color: #134a80;
  margin-bottom: 20px;
  font-weight: 700;
}

.Forgot_form form {
  display: flex;
  flex-direction: column;
}

.Forgot_form label {
  text-align: left;
  margin-bottom: 5px;
}

.Forgot_form input {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  outline: none;
}

.Forgot_form .forgotpassword_btn {
  padding: 8px;
  background-color: #134a80;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.Forgot_form .card .avtar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px whitesmoke;
  padding: 10px;
}
.Forgot_form .card .avtar img {
  width: 100%;
}
