/* Add this CSS to your video.css file */
.main_videoSec {
  padding: 3% 5%;
}

.main_videoSec .videoPlay {
  position: relative;
  width: 100%;
  border-radius: 8px;
  box-shadow: 3px 3px 10px 3px #f0f0f0;
}

.main_videoSec .videoThumbnail {
  cursor: pointer;
  height: 210px;
  background-color: black;
  border-radius: 10px 10px 0px 0px;
}
.main_videoSec .videoThumbnail img {
  width: 100%;
  height: 210px;
  border-radius: 8px 8px 0px 0px;
  opacity: 0.5;
}
.main_videoSec .video_icon_box {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 37%;
  right: 45%;
  border: 2px solid white;
  /* background-color: #ffffff; */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 1px var(--color-border, rgb(0 0 0 / 10%)) inset;
}
.main_videoSec .video_icon_box .icon {
  font-size: 20px;
  color: white;
}

.main_videoSec .videoPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
}

.main_videoSec .videoPopup video {
  width: 600px;
  height: 100%;
}

.main_videoSec .video_content {
  text-align: justify;
  font-size: 15px;
}
.main_videoSec .video_content h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size:18px;
}

.main_videoSec .closeIcon {
  background-color: transparent;
}
.main_videoSec .closebtn_icon {
  font-size: 30px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.main_videoSec .ViewAll {
  background-color: #134a80;
  color: white;
  padding: 7px 25px;
}

.main_videoSec .ViewAll:hover {
  background-color: #134a80;
  color: white;
}

/* banner video ------------------------------------------------- */
.videoHeader section {
  background-color: black;
  height: 50vh;
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 1500px) {
  .main_videoSec .videoPlay {
    height: 300px;
  }
  .main_videoSec .video_content {
    text-align: left;
  }
}

@media screen and (max-width: 650px) {
  .main_videoSec .videoPopup video {
    width: 450px !important;
  }
}

@media screen and (max-width: 500px) {
  .main_videoSec .videoPopup video {
    width: 350px !important;
  }
}

@media screen and (max-width: 380px) {
  .main_videoSec .videoPopup video {
    width: 300px !important;
  }
}
