@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.global_button_colored {
  border: 1px solid #134a80;
  background-color: #134a80;
  color: white;
  font-size: 14px;
  border-radius: 3px;
  padding: 5px 15px;
}
.global_button_colored:hover {
  background-color: white;
  color: #134a80;
}

.global_button_white {
  border: 1px solid #134a80;
  background-color: #ffffff;
  color: #134a80;
  font-size: 14px;
  border-radius: 3px;
  padding: 5px 15px;
}
.global_button_white a:hover {
  color: white !important;
}
.global_button_white:hover {
  background-color: #134a80;
  color: #ffffff;
}

.global_routing_Navbar_bottom {
  padding: 1% 5% 0% 5%;
}
body > iframe {
  display: none;
}
@media screen and (max-width: 800px) {
  .global_button_colored {
    font-size: 14px;
  }
  .global_button_white {
    font-size: 14px;
  }
}
@media screen and (max-width: 550px) {
  .global_button_colored {
    font-size: 13px;
  }
  .global_button_white {
    font-size: 13px;
  }
}

@media screen and (max-width: 425px) {
  #WindProfiles_viewAll {
    font-size: 15px;
  }
  #Weather_viewAll {
    font-size: 15px;
  }
  #RecentlyViewed_viewAll {
    font-size: 15px;
  }
  #ForecastingPlatform_viewAll {
    font-size: 15px;
  }
  .global_routing_Navbar_bottom span {
    font-size: 12px;
  }
}
