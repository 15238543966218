.QuoteForm_model input {
  padding: 10px;
  border-radius: 8px;
  outline: none;
  border: 2px solid whitesmoke;
}
.QuoteForm_model .textarea {
  border: 2px solid whitesmoke;
  border-radius: 8px;
  padding: 10px;
  outline: none;
}

.QuoteForm_model input[type="submit"] {
  background-color: #134a80;
  color: white;
  padding: 8px 20px;
}

.QuoteForm_model select {
  padding: 10px;
  border-radius: 8px;
  outline: none;
  border: 2px solid whitesmoke;
}

@media screen and (max-width: 500px) {
  .QuoteForm_model input {
    width: 100%;
  }
}
