.FilterProduct {
  padding: 0%;
}

.filter_image {
  height: 200px;
}

.filter_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.FilterProduct-card {
  box-shadow: 3px 3px 10px 3px rgb(240, 240, 240);
  border-radius: 12px;
}

.FilterProduct-card .FilterProduct-details {
  padding: 4%;
}
.FilterProduct-card .FilterProduct-details-heart p {
  font-size: 18px;
  font-weight: 600;
  width: 90%;
}
.FilterProduct-card .FilterProduct-details-heart .heart_icon {
  color: #062c52;
  font-size: 20px;
  cursor: pointer;
}
.FilterProduct-card .FilterProduct-details .text-start {
  font-size: 13px;
  line-height: 22px;
}

.WeatherRadar-heading {
  font-weight: 600;
}
.category_paginate .pagination {
  display: flex;
  justify-content: center;
  margin-top: 65px;
}

.category_paginate .pagination a {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.category_paginate .pagination a.active {
  color: white !important;
  background: #134a80;
}

.category_paginate .pagination a:hover {
  background: #134a80;
  color: white !important;
}

.category_paginate .pagination li:hover {
  text-decoration: none !important;
}

.category_paginate .pagination a.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.category_paginate .pagination .previous a {
  color: black;
  text-decoration: none;
}

.category_paginate .pagination .next a {
  color: black;
  text-decoration: none;
}

.category_paginate .active-page a {
  background: #134a80;
  color: white !important;
}

.category_paginate .pagination li {
  margin: 0px 1px 0px 1px;
}
