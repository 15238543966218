.Header_banner img {
  width: 100%;
  height: 400px;
}

@media screen and (max-width: 768px) {
  .Header_banner img {
    height: 320px;
  }
}
