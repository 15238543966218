.ComparisonTable{
    padding:3% 5%;
    overflow-x: auto;
    
}
.ComparisonTable .Comparison{
    background-color: #134A80;
    color:white;
}

.ComparisonTable table, th, td {
    border-collapse: collapse;
  }
  .ComparisonTable  td, th {
    text-align: left;
    padding: 8px;
  }
  .ComparisonTable  table, th, td {
    border-collapse: collapse;
  }
  .ComparisonTable  td, th {
    text-align: left;
    padding: 8px;
  }
  .ComparisonTable  tr:nth-child(even) {
    background-color: #dddddd;
  }