.PortfolioDetails {
    padding: 3% 5%;
  }
  .PortfolioDetails .recentPost .news_Category .category-title {
    padding: 10px 10px 20px 10px;
    width: 100%;
    text-align: start;
  }
  .PortfolioDetails .recentPost .news_Category {
    border: 2px solid #eeeeee;
    border-radius: 10px;
    padding: 10px 0px 15px 0px;
  }
  .PortfolioDetails .recentPost .news_Category .category-title h5 {
    color: #134a80;
  }
  .PortfolioDetails .recentPost .news_Category .category-title .progresbar {
    width: 100%;
    background-color: #ebe5e5;
    border-radius: 5px;
  }
  
  .PortfolioDetails .progres_line {
    background-color: #134a80;
    padding: 2px;
    width: 35%;
  }
  .PortfolioDetails .NewsFilters {
    text-align: start;
  }
  .PortfolioDetails .NewsFilters p {
    padding: 0px 10px;
    font-weight: bold;
  }
  .PortfolioDetails .NewsFilters hr {
    margin: 10px 0px;
    border: 1px solid;
  }
  
  .PortfolioDetails .NewsFilters .details .iconsSec span {
    font-size: 10px;
  }
  .PortfolioDetails .NewsFilters .details .iconsSec .i {
    font-size: 12px;
    color: #134a80;
  }
  .PortfolioDetails .NewsFilters .recent_viewSec {
    padding: 0px 10px;
    overflow: hidden;
    width: 100%;
  }
  .PortfolioDetails .NewsFilters .recent_viewSec .leftSec img {
    width: 100%;
    border-radius: 7px;
  }
  .PortfolioDetails .NewsFilters .recent_viewSec .leftSec {
    width: 33%;
  }
  .PortfolioDetails .NewsFilters .recent_viewSec .right_Sec {
    width: 62%;
  }
  
  .PortfolioDetails .newsDetailsleftSec .imagesec {
    height: 400px;
  }
  .PortfolioDetails .newsDetailsleftSec .imagesec .newsproductImg {
    width: 100%;
    height: 400px;
  }
  .PortfolioDetails .newsDetailsleftSec .newsDetailsleftSec_content {
    text-align: justify;
  }
  
  @media screen and (max-width: 767px) {
    .PortfolioDetails .news_Category .NewsFilters .right_Sec h6 {
      font-size: 20px !important;
    }
    .PortfolioDetails .NewsFilters .details .iconsSec span {
      font-size: 14px !important;
    }
    .PortfolioDetails .NewsFilters .details .iconsSec .i {
      font-size: 16px !important;
    }
  }
  