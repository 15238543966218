.CategoriesNavBar-most_popular {
  border: 1px solid;
  padding: 4px 10px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
}
.CategoriesNavBar-product_list {
  position: absolute;
  bottom: -200px;
  width: 100%;
  z-index: 99;
}
.CategoriesNavBar-most_popular span {
  font-size: 15px;
}
.CategoriesNavBar-product_list li {
  padding: 2%;
}
.CategoriesNavBar-product_list li:hover {
  background-color: #134a80;
  color: white;
}

.filterBar {
  cursor: pointer;
}

@media screen and (max-width: 375px) {
  .plateForm {
font-size:18px
  }
}

