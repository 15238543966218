.AddCard {
  padding: 3% 5%;
}
.AddCard-details {
  padding: 4%;
  width: 100%;
}

.comparison_image {
  height: 200px;
}

.addcard_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.AddCard .AddCard-details-heart .heart_icon {
  color: #062c52;
  font-size: 20px;
}

.AddCard .AddCard-details-heart p {
  font-size: 20px;
  font-weight: 600;
  width: 90%;
}
.AddCard .AddCard-card img {
  width: 100%;
}

.AddCard .AddCard-card {
  box-shadow: 3px 3px 10px 3px rgb(240, 240, 240);
  border-radius: 12px;
  border-radius: 15px;
  height: 100%;
}
.AddCard .AddCard-card .plus_icon {
  font-size: 30px;
  color: #062c52;
  cursor: pointer;
}
.AddCard-card {
  position: relative;
}
.crossIcon {
  color: grey;
  font-size: 16px;
}

.cross_icon_box {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 1px var(--color-border, rgb(0 0 0 / 10%)) inset;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .comparison_image {
    height: 190px;
  }
}
@media screen and (max-width: 850px) {
  .AddCard .AddCard-details-heart p {
    font-size: 17px;
  }
  .comparison_image {
    height: 170px;
  }
}
@media screen and (max-width: 768px) {
  .comparison_image {
    height: 23 0px;
  }
}

@media screen and (max-width: 565px) {
  .ComparisonTable td,
  th {
    font-size: 13px;
  }
}
@media screen and (max-width: 470px) {
  .ComparisonTable td,
  th {
    font-size: 11px;
  }
}
@media screen and (max-width: 375px) {
  .ComparisonTable td,
  th {
    font-size: 9px;
    padding: 5px;
  }
}
