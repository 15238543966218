.PrivacyPolicybg-body .policy_content {
  padding: 3% 5%;
  text-align: justify;
}
.PrivacyPolicybg-body .policy_header {
  height: 50vh;
  background: linear-gradient(#13498049, #13498084),
    url("../../src/Public/images/privatepoliceHeader_img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.PrivacyPolicybg-body .policy_header_content {
  width: 60%;
}

@media only screen and (max-width: 900px) {
  .PrivacyPolicybg-body .policy_header_content {
    width: 80%;
  }
  .PrivacyPolicybg-body .policy_header_content p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 500px) {
  .PrivacyPolicybg-body .policy_header_content {
    width: 95%;
  }
  .PrivacyPolicybg-body .policy_header_content p {
    font-size: 12px;
  }
}
