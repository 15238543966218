.ContactUssection {
  position: relative;
  padding: 3% 5%;
  background-color: #f5f5f5;
}
.ContactUssection .contact-name {
  margin-bottom: 30px;
  text-align: left;
}
.ContactUssection .contact-name h5 {
  font-size: 22px;
  color: #134a80;
  margin-bottom: 5px;
  font-weight: 600;
}
.ContactUssection .contact-name p {
  font-size: 18px;
  margin: 0;
}

.ContactUssection .social-share a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  margin-right: 10px;
}
.ContactUssection .social-share .dribbble {
  box-shadow: 0 8px 30px -4px rgba(234, 76, 137, 0.5);
  background-color: #ea4c89;
}
.ContactUssection .social-share .behance {
  box-shadow: 0 8px 30px -4px rgba(0, 103, 255, 0.5);
  background-color: #0067ff;
}
.ContactUssection .social-share .linkedin {
  box-shadow: 0 8px 30px -4px rgba(1, 119, 172, 0.5);
  background-color: #0177ac;
}

.ContactUssection .contact-form .form-control {
  border: none;
  border-bottom: 1px solid #20247b;
  background: transparent;
  border-radius: 0;
  padding: 18px 0px 18px 0px;
  box-shadow: none !important;
}

.AAAAA{
  width:100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #20247b;
  background: transparent;
  border-radius: 0;
  padding: 18px 0px 18px 0px;
  box-shadow: none !important;
  cursor: pointer;
  
}
.ContactUssection .contact-form .form-control:focus {
  border-bottom: 1px solid #fc5356;
}
.ContactUssection .contact-form .form-control.invalid {
  border-bottom: 1px solid #ff0000;
}
.ContactUssection .contact-form .send {
  margin-top: 20px;
  text-align: left;
}
@media (max-width: 767px) {
  .ContactUssection .contact-form .send {
    margin-bottom: 20px;
  }
}

.ContactUssection .section-title h2 {
  font-weight: 700;
  color: #134a80;
  font-size: 45px;
  margin: 0 0 15px;
  border-left: 5px solid #55f66a;
  padding-left: 15px;
  text-align: left;
}
.ContactUssection .section-title {
  padding-bottom: 45px;
}
.ContactUssection .contact-form .send {
  margin-top: 20px;
}
.ContactUssection .px-btn {
  padding: 0 50px 0 20px;
  line-height: 60px;
  position: relative;
  display: inline-block;
  color: #134a80;
  font-weight: bold;
  background: none;
  border: none;
}
.ContactUssection .px-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 30px;
  background: transparent;
  border: 2px solid #55f66a;
  border-right: 1px solid transparent;
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
  width: 60px;
  height: 60px;
}
.ContactUssection .px-btn .arrow {
  width: 13px;
  height: 2px;
  background: currentColor;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 25px;
  z-index: 999;
}
.ContactUssection .px-btn .arrow:after {
  width: 8px;
  height: 8px;
  border-right: 2px solid currentColor;
  border-top: 2px solid currentColor;
  content: "";
  position: absolute;
  top: -3px;
  right: 0;
  display: inline-block;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (max-width: 768px) {
  .ContactUssection .section-title {
    padding-bottom: 0px;
  }
}

@media (max-width: 430px) {
  .ContactUssection .section-title h2 {
    font-size: 35px;
  }
}
