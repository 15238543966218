.NewsRIghtSec .productCard .images {
  height: 250px;
}
.NewsRIghtSec .productCard {
  border-radius: 10px;
  box-shadow: 3px 3px 20px 3px whitesmoke;
  padding: 13px;
}

.NewsRIghtSec .productCard .images img {
  width: 100%;
  height: 100%;
}

.NewsRIghtSec .productCard .productContent .icons_Sec span {
  font-size: 14px;
}
.NewsRIghtSec .productCard .productContent p {
  text-align: justify;
}
.NewsRIghtSec .productCard .productContent .icons_Sec .i {
  font-size: 14px;
  color: #134a80;
}
.NewsRIghtSec .productCard .productContent a {
  color: #134a80;
  font-size: 14px;
  margin-top: 10px;
}
.newsRightsecText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 990px) {
  .NewsRIghtSec .productCard .images {
    height: 200px;
  }
  .NewsRIghtSec .productCard .productContent p {
    text-align: left;
  }
}

@media screen and (max-width: 870px) {
  .NewsRIghtSec .productCard .productContent h5 {
    font-size: 17px;
  }
  .NewsRIghtSec .productCard .productContent p {
    font-size: 13px;
  }
}
