.wishlist_image {
  width: 200px;
  height: 100px;
}

.wishlist_card {
  box-shadow: -2px 0px 9px 9px whitesmoke;
}

.wishlist_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wishlist_name {
  font-weight: bold;
  font-size: 16px;
}

.wishlist_description {
  font-size: 14px;
}

@media screen and (max-width: 576px) {
  .wishlist_image {
    width: 100%;
    height: 200px;
  }
}
